<template>
  <div class="team">
    <div class="box">
      <h3>{{ systemSettins.aboutTitle }}</h3>
      <p class="title">“读万卷书，行万里路“</p>
      <p class="text">
        {{ systemSettins.aboutContent }}
      </p>
    </div>
    <div style="text-align:center">
      <video
        controls
        poster="./img/fm.jpg"
        :src="systemSettins.teamVideo"
        style="width:980px; height:551px;"
      ></video>
    </div>
    <div class="box">
      <h3>{{ systemSettins.timeTitle }}</h3>
      <p class="text">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in JSON.parse(systemSettins.timeContent)"
            :key="index"
            :timestamp="activity.timestamp"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </p>
    </div>
    <div class="box">
      <h3>{{ systemSettins.toTeacherTitle }}</h3>
      <div class="line"></div>
      <div class="text" v-html="systemSettins.toTeacherContent">
      </div>
    </div>
    <div class="box" style="text-align: left;">
      <h3>{{ systemSettins.joinOusTitle }}</h3>
      <div class="line"></div>
      <div v-html="systemSettins.joinOusContent"></div>
      <br />
      <br />
    </div>
    <div class="footer">
      <div class="bottom">
        <span>{{ systemSettins.copyright }}</span
        ><span>备案号：{{ systemSettins.filings }}</span
        ><span>联系方式：{{ systemSettins.email }}</span>
        <span style="text-decoration: underline; cursor: pointer;" @click="letterFn">致谢与致歉</span>
      </div>
    </div>
    <letter ref="letter"></letter>
  </div>
</template>

<script>
import letter from "@/components/letter.vue";
import { settingsDetail } from "@/api/Mange/Mange";

export default {
  components: {
    letter,
  },

  data() {
    return {
      systemSettins: {},
      activities: [
        {
          "content": "vr-campustour.com正式上线",
          "timestamp": "2023年4月1日",
        },
        {
          "content": "阿里云短信注册服务审核通过",
          "timestamp": "2023年3月29日",
        },
        {
          "content": "网站工信部备案审核通过（京ICP备2022016689号-2）",
          "timestamp": "2023年3月28日",
        },
        {
          "content": "用户系统开发完毕调试",
          "timestamp": "2023年3月27日",
        },
        {
          "content": "中央民族大学内测上线",
          "timestamp": "2023年3月22日",
        },
        {
          "content": "哈尔滨工程大学内测上线",
          "timestamp": "2023年3月18日",
        },
        {
          "content": "网站用户系统开发开始",
          "timestamp": "2023年2月16日",
        },
        {
          "content": "长安大学内测上线",
          "timestamp": "2023年1月7日",
        },
        {
          "content": "清华大学、人民大学、北京化工大学、中国石油大学内测上线",
          "timestamp": "2022年12月1日",
        },
        {
          "content": "北京大学内测上线。 2022年11月8日，开通阿里云服务器账号",
          "timestamp": "2022年11月9日",
        },
        {
          "content": "vr-campustour.com微信工作群建立；",
          "timestamp": "2022年11月7日",
        },
        {
          "content": "域名vr-campustour.com注册成功",
          "timestamp": "2022年10月25日",
        }
      ],
    };
  },
  created() {
    settingsDetail().then(res => {
      this.systemSettins = res.data;
    })
  },
  methods: {
    letterFn(){
      this.$refs.letter.showDialog();
    },
    jump(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less">
.team {
  .btn {
    cursor: pointer;
  }
  .btn:hover {
    color: #d14649;
  }
  .el-timeline-item__content {
    font-weight: bold;
  }
  .el-timeline-item__timestamp {
    color: #bcbcbc;
    font-size: 12px;
  }
}
</style>
